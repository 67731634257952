import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchJobsStartAsync } from "../../redux/job/job.actions";
import JobActionsTypes from "../../redux/job/job.types";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  FormGroup,
  FormTextarea,
  Button,
} from "shards-react";
import CreatableSelect from "react-select/creatable";
import { post, get, put } from "../../utils/http";
import { usePosition } from "use-position";
import JobTable from "./jobTableForm";
import {BASE_URL} from "../../constants/API-routes";

const FormBody = ({ toggleModal, fetchJobsStartAsync, currentJob }) => {
  const [state, setState] = useState(currentJob);
  const [industries, setIndustries] = useState([]);
  const [subindustries, setSubindustries] = useState([]);
  const [skills, setSkills] = useState([]);
  const { latitude, longitude, error } = usePosition();

  const types = {
    jobTypes: ["full-time", "contract", "part-time"],
    salaries: ["Per hour", "Per month", "Per year", "Depending Experience"],
    mode: ["on-site", "remote", "hybrid"],
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "industry") {
      const industry = industries.find(
        (industry) => industry.name === e.target.value
      );
      setSubindustries(industry.subIndustry);
    }
  };

  const handleSkillsChange = (skills) => {
    setState({
      ...state,
      skills,
    });
  };

  //TODO validation
  //buttons enable only when valid

  const handleSaveExit = async () => {
    try {
      console.log("inside handleSave exit", currentJob, state);
      if (!currentJob._id) {
        await post(BASE_URL+"job", {
          body: {
            ...state,
            skills: state.skills.map((v) => v.value),
            type: state.type === "contract" ? "freelance" : state.type,
            isCurated: true,
          },
        });
      } else {
        await put(BASE_URL+"job", {
          body: {
            ...state,
            _id: currentJob._id,
            skills: state.skills.map((v) => v.value),
            type: state.type === "contract" ? "freelance" : state.type,
            isCurated: true,
          },
        });
      }
      toggleModal();
      fetchJobsStartAsync();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const industries = await get(BASE_URL+"job/industry");
        setIndustries(industries);
        if (state.subIndustry && state.industry) {
          const industry = industries.find(
            (industry) => industry.name === state.industry
          );
          setSubindustries(industry.subIndustry);
        }
      } catch (err) {
        console.error(err.message);
      }
    };

    const fetchSkills = async () => {
      try {
        const dbSkills = await get(BASE_URL+"skill");
        let skillsOptions = [];
        dbSkills.forEach((skill) => {
          skillsOptions.push({
            value: skill.name,
            label: skill.name,
          });
        });
        setSkills(skillsOptions);
      } catch (err) {
        console.error(err.message);
      }
    };

    const fetchLocation = (lat, long) => {
      const KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${KEY}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        });
    };
    fetchIndustries();
    fetchSkills();

    if (latitude && longitude && !error) {
      fetchLocation(latitude, longitude);
    }
  }, [latitude, longitude]);

  return (
    <Col md="12">
      <Form>
        <Row form>
          <Col md="6" className="form-group">
            <label htmlFor="company">Company</label>
            <FormInput
              type="text"
              id="company"
              name="company"
              placeholder="Enter Company"
              value={state.company}
              onChange={(e) => handleChange(e)}
            />
          </Col>
          <Col md="6" className="form-group">
            <label htmlFor="jobTitle">Job Title</label>
            <FormInput
              type="text"
              id="jobTitle"
              name="jobTitle"
              placeholder="Enter Job Title"
              value={state.jobTitle}
              onChange={(e) => handleChange(e)}
            />
          </Col>
        </Row>
        <Row form>
          <Col md="6" className="form-group">
            <label htmlFor="industry">Industry</label>
            <FormSelect
              id="industry"
              name="industry"
              value={state.industry}
              onChange={(e) => handleChange(e)}
            >
              <option>Choose ...</option>
              {industries.map((industry, idx) => (
                <option key={idx}>{industry.name}</option>
              ))}
            </FormSelect>
          </Col>
          <Col md="6" className="form-group">
            <label htmlFor="subIndustry">Subindustry</label>
            <FormSelect
              id="subIndustry"
              name="subIndustry"
              value={state.subIndustry}
              onChange={(e) => handleChange(e)}
            >
              <option>Choose ...</option>
              {subindustries.map((subindustry, idx) => (
                <option key={idx}>{subindustry.name}</option>
              ))}
            </FormSelect>
          </Col>
        </Row>
        <Row form>
          <Col md="6" className="form-group">
            <label htmlFor="skills">Skills</label>
            <CreatableSelect
              id="skills"
              placeholder="Select Skills or enter a new"
              isMulti
              options={skills}
              value={state.skills}
              onChange={handleSkillsChange}
            />
          </Col>
          <Col md="6" className="form-group">
            <label htmlFor="type">Type</label>
            <FormSelect
              id="type"
              name="type"
              value={state.type}
              onChange={(e) => handleChange(e)}
            >
              <option>Choose ...</option>
              {types.jobTypes.map((type, idx) => (
                <option key={idx}>{type}</option>
              ))}
            </FormSelect>
          </Col>
        </Row>
        <Row form>
          <Col md="6" className="form-group">
            <label htmlFor="salary">Salary</label>
            <FormInput
              type="number"
              id="salary"
              name="salary"
              placeholder="Enter Salary"
              value={state.salary}
              onChange={(e) => handleChange(e)}
            />
          </Col>
          <Col md="6" className="form-group">
            <label htmlFor="salaryType">Salary Type</label>
            <FormSelect
              id="salaryType"
              name="salaryType"
              value={state.salaryType}
              onChange={(e) => handleChange(e)}
            >
              <option>Choose ...</option>
              {types.salaries.map((type, idx) => (
                <option key={idx}>{type}</option>
              ))}
            </FormSelect>
          </Col>
        </Row>
        <Row form>
          <Col md="6" className="form-group">
            <label htmlFor="contactName">Contact Name</label>
            <FormInput
              type="text"
              id="contactName"
              name="contactName"
              placeholder="Enter Contact Name"
              value={state.contactName}
              onChange={(e) => handleChange(e)}
            />
          </Col>
          <Col md="6" className="form-group">
            <label htmlFor="contact">Contact</label>
            <FormInput
              type="text"
              id="contact"
              name="contact"
              placeholder="Enter Contact Email"
              value={state.contact}
              onChange={(e) => handleChange(e)}
            />
          </Col>
        </Row>
        <Row form>
          <Col md="4" className="form-group">
            <label htmlFor="zip">Zip Code</label>
            <FormInput
              type="text"
              id="zip"
              name="zip"
              placeholder="Enter Zip Code"
              value={state.zip}
              onChange={(e) => handleChange(e)}
            />
          </Col>
          <Col md="4" className="form-group">
            <label htmlFor="country">Country</label>
            <FormInput
              type="text"
              id="country"
              name="country"
              placeholder="Enter Country Name"
              value={state.country}
              onChange={(e) => handleChange(e)}
            />
          </Col>
          <Col md="4" className="form-group">
            <label htmlFor="city">City</label>
            <FormInput
              type="text"
              id="city"
              name="city"
              placeholder="Enter City Name"
              value={state.city}
              onChange={(e) => handleChange(e)}
            />
          </Col>
        </Row>
        <FormGroup>
          <label htmlFor="description">Description</label>
          <FormTextarea
            className="form-textarea"
            id="description"
            name="description"
            placeholder="Enter a brief description"
            value={state.description}
            onChange={(e) => handleChange(e)}
          />
        </FormGroup>
        <Row>
          <Col md="6" className="form-group">
              <label htmlFor="mode">Mode</label>
              <FormSelect
                id="mode"
                name="mode"
                value={state.mode}
                onChange={(e) => handleChange(e)}
              >
                <option>Choose ...</option>
                {types.mode.map((type, idx) => (
                  <option key={idx}>{type}</option>
                ))}
              </FormSelect>
            </Col>
        </Row>
      </Form>
      <div className="buttons-bar">
        <Button className="mr-2" onClick={handleSaveExit}>
          Save
        </Button>
        {/* <Button>Save and create Another job</Button> */}
      </div>
    </Col>
  );
};

const JobForm = (props) => {
  const { mode, changeModeForm } = props;
  return (
    <div>
      <Modal open={props.open} size="lg" backdrop="static">
        <ModalHeader>
          <Button
            theme="danger"
            onClick={props.toggle}
            style={{ position: "absolute", right: "10px" }}
          >
            X
          </Button>
          Post a Job
        </ModalHeader>
        <ModalBody>
          {mode == "form" ? (
            <FormBody
              toggleModal={props.toggle}
              fetchJobsStartAsync={props.fetchJobsStartAsync}
              currentJob={props.currentJob}
            />
          ) : (
            <JobTable
              toggleModal={props.toggle}
              fetchJobsStartAsync={props.fetchJobsStartAsync}
              setMode={changeModeForm}
            />
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ jobs }) => ({
  mode: jobs.Mode,
});
const dispatchToProps = (dispatch) => ({
  fetchJobsStartAsync: () => dispatch(fetchJobsStartAsync()),
  changeModeForm: (payload) => {
    dispatch({
      type: JobActionsTypes.SET_FORM_MODE,
      payload,
    });
  },
});

export default connect(mapStateToProps, dispatchToProps)(JobForm);
