import JobActionsTypes from "./job.types";

const INITIAL_STATE = {
  items: null,
  isFetching: false,
  errorMessage: undefined,
  Mode:'form',
  fileData:[],
  hasError:false
};

const jobReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case JobActionsTypes.FETCH_JOBS_START:
      return {
        ...state,
        isFetching: true,
      };
    case JobActionsTypes.FETCH_JOBS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        isFetching: false,
      };
    case JobActionsTypes.FETCH_JOBS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
      case JobActionsTypes.SET_FORM_MODE:
        return {
          ...state,
          Mode: action.payload,
        };
        case JobActionsTypes.SET_FILE_DATA:
        return {
          ...state,
          fileData: [...state.fileData, action.payload,]
        };
        case JobActionsTypes.RESET_FILE_DATA:
          return {
            ...state,
            fileData: []
          };
          case JobActionsTypes.CHECK_JOBS_ERROR:
            return {
              ...state,
              hasError: action.payload,
            };
        case JobActionsTypes.UPDATE_FILE_DATA:
           return Object.assign({}, state, {
             fileData: state.fileData.map(item => {
              return item.id === action.payload.id ? action.payload : item;
             }) // replace matched item and returns the array 
              });
    default:
      return state;
  }
};

export default jobReducer;
