import React from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../../../redux/nav-items/nav-items.actions";
import { Navbar, NavbarBrand } from "shards-react";

const SidebarMainNavbar = (props) => {
  const handleToggleSidebar = () => {
    const { toggleSidebar } = props;
    toggleSidebar();
  };
  return (
    <div className="main-navbar">
      <Navbar
        className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
        type="light"
      >
        <NavbarBrand
          className="w-100 mr-0"
          href="#"
          style={{ lineHeight: "25px" }}
        >
          <div className="d-table m-auto">
            <img
              id="main-logo"
              className="d-inline-block align-top mr-1"
              style={{ maxWidth: "25px" }}
              src={require("../../../images/shards-dashboards-logo.svg")}
              alt="Shards Dashboard"
            />
            <span className="d-none d-md-inline ml-1">Jolt Mate Dashboard</span>
          </div>
        </NavbarBrand>
        {/* eslint-disable-next-line */}
        <a
          className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
          onClick={handleToggleSidebar}
        >
          <i className="material-icons">&#xE5C4;</i>
        </a>
      </Navbar>
    </div>
  );
};

const dispatchToProps = (dispatch) => ({
  toggleSidebar: () => dispatch(toggleSidebar()),
});

export default connect(null, dispatchToProps)(SidebarMainNavbar);
