import { createSelector } from "reselect";

const selectNavItems = (state) => state.navItems;

export const selectNavMenuItems = createSelector(
  [selectNavItems],
  (navItems) => navItems.menuItems
);

export const selectMenuVisible = createSelector(
  [selectNavItems],
  (navItems) => navItems.menuVisible
);
