/* eslint-disable */
import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Card, CardBody, FormInput, Button } from "shards-react";
import { ExcelRenderer } from "react-excel-renderer";
import { post } from "../../utils/http";
import JobActionsTypes from "../../redux/job/job.types";
import isEmpty from "lodash.isempty";
import { FaPen } from "react-icons/fa";
import ErrorFile from "./errorFile";
import FormBody from "./formBody";
import ExampleFile from "../../constants/fileFormat.xlsx";
import "./style.css";
import { BASE_URL } from "../../constants/API-routes"

const JobTable = ({
  toggleModal,
  fetchJobsStartAsync,
  setMode,
  changeFileData,
  fileData,
  resetFileData,
  hasError,
  checkErrors,
}) => {
  const [show, setShow] = useState(false);
  const [job, setJob] = useState({});

  const currentJob = {
    company: "",
    jobTitle: "",
    industry: "",
    subIndustry: "",
    skills: [],
    type: "",
    salary: "",
    salaryType: "",
    contactName: "",
    contact: "",
    zip: "",
    country: "",
    city: "",
    description: "",
    mode: "",
  };

  const fileHandler = (event) => {
    resetFileData();
    let fileObj = event.target.files[0];
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        delete resp.rows[0];
        resp.rows.map((d, v) => {
          if (!isEmpty(d)) {
            console.log(d[4]);
            currentJob.skills = [];
            currentJob.id = v;
            currentJob.company = d[0];
            currentJob.jobTitle = d[1];
            currentJob.industry = d[2];
            currentJob.subIndustry = d[3];
            if (!isEmpty(d[4])) {
              currentJob.skills = d[4].split(",");
            }
            currentJob.type = d[5];
            currentJob.salary = d[6];
            currentJob.salaryType = d[7];
            currentJob.contactName = d[8];
            currentJob.contact = d[9];
            currentJob.zip = d[10];
            currentJob.country = d[11];
            currentJob.city = d[12];
            currentJob.description = d[13];
            currentJob.mode = d[14];
            changeFileData({ ...currentJob });
          }
        });
      }
    });
  };
  const handelSaveFile = () => {
    fileData.map((d, v) => {
      handleSaveExit(d);
      console.log(d);
    });
    resetFileData();
    checkErrors(false);
  };

  const handleSaveExit = async (currentJob) => {
    try {
      await post(BASE_URL+"job", {
        body: {
          ...currentJob,
          skills: currentJob.skills.map((v) => v),
          type: currentJob.type === "contract" ? "freelance" : currentJob.type,
          isCurated: true,
        },
      });

      toggleModal();
      fetchJobsStartAsync();
      setMode("form");
    } catch (err) {
      console.error(err);
    }
  };

  const removeTarget = (event) => {
    event.target.value = null;
  };

  const updateJob = (job) => {
    setShow(true);
    setJob({
      ...job,
      skills: job.skills.map((skill) => {
        return {
          value: skill,
          label: skill,
        };
      }),
    });
  };
  const setFirstsTwoSkills = (skills) => {
    if (skills[0] && skills[1]) {
      return `${skills[0]}, ${skills[1]}`;
    } else if (skills[0]) {
      return `${skills[0]}`;
    }
  };
  const getLocation = (country, city) => {
    if (!isEmpty(country) && !isEmpty(city)) {
      return `${country}, ${city}`;
    }
  };

  const submitButtonStyle = (_this) => {
    if (hasError) {
      _this.style.backgroundColor = "red";
    }
  };

  return (
    <div>
      {/* <FormInput  type="file" placeholder="My form input" onChange={fileHandler}  onClick={()=>removeTarget(event)}/> */}
      {/* <Button  className="text-md" onChange={fileHandler}  onClick={()=>removeTarget(event)}>
       Upload a File!
     </Button>
   <input id="actual-upload" type="file" style={{display:"none"}}/>  */}
      <div className="contanier">
        <input
          type="file"
          id="file"
          onChange={fileHandler}
          onClick={() => removeTarget(event)}
        />
        <label for="file">Choose a file</label>
        <a href={ExampleFile} download className="download-text">
          {" "}
          Download a sample template{" "}
        </a>
      </div>
      {!isEmpty(fileData) && (
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <table
                  className="table mb-0 text-center"
                  style={{ fontSize: "10px" }}
                >
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        Company
                      </th>
                      <th scope="col" className="border-0">
                        Job Title
                      </th>
                      {/*  <th scope="col" className="border-0">
                     Industry
                  </th>
                  <th scope="col" className="border-0">
                    Sub Industry
                  </th> */}
                      <th scope="col" className="border-0">
                        Location
                      </th>
                      <th scope="col" className="border-0">
                        Skills
                      </th>
                      <th scope="col" className="border-0">
                        Type
                      </th>
                      <th scope="col" className="border-0">
                        Salary
                      </th>
                      <th scope="col" className="border-0">
                        Salary Type
                      </th>
                      {/* <th scope="col" className="border-0">
                     Contact Name
                  </th> */}
                      <th scope="col" className="border-0">
                        Contact
                      </th>
                      {/* <th scope="col" className="border-0">
                     Zip
                  </th>
                  <th scope="col" className="border-0">
                  Description
                  </th> */}
                      <th scope="col" className="border-0">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fileData.map((d, v) => {
                      return (
                        <tr key={v}>
                          <td>{d.company}</td>
                          <td>{d.jobTitle}</td>
                          {/*  <td>{d.industry}</td>
                      <td>{d.subIndustry}</td>*/}
                          <td>{getLocation(d.country, d.city)}</td>
                          <td>{setFirstsTwoSkills(d.skills)}</td>
                          <td>{d.type}</td>
                          <td>{d.salary}</td>
                          <td>{d.salaryType}</td>
                          {/* <td>{d.contactName}</td> */}
                          <td>{d.contact}</td>
                          {/* <td>{d.zip}</td>
                      <td>{d.description}</td> */}
                          <td className="actions-col">
                            <FaPen onClick={(e) => updateJob(d)} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      {!isEmpty(fileData) && <ErrorFile fileData={fileData} />}
      {show && <FormBody currentJob={job} show={setShow} />}
      {hasError == true ? (
        <Button className="mr-2" onClick={handelSaveFile}>
          Save
        </Button>
      ) : (
        <Button theme="secondary" disabled={true}>
          Save
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = ({ jobs }) => ({
  fileData: jobs.fileData,
  hasError: jobs.hasError,
});

const dispatchToProps = (dispatch) => ({
  changeModeForm: (payload) => {
    dispatch({
      type: JobActionsTypes.SET_FORM_MODE,
      payload,
    });
  },
  changeFileData: (payload) => {
    dispatch({
      type: JobActionsTypes.SET_FILE_DATA,
      payload,
    });
  },
  resetFileData: () => {
    dispatch({
      type: JobActionsTypes.RESET_FILE_DATA,
    });
  },
  updateFileData: (payload) => {
    dispatch({
      type: JobActionsTypes.UPDATE_FILE_DATA,
      payload,
    });
  },
  checkErrors: (payload) => {
    dispatch({
      type: JobActionsTypes.CHECK_JOBS_ERROR,
      payload,
    });
  },
});

export default connect(mapStateToProps, dispatchToProps)(JobTable);
