import NavItemsActionsTypes from "./nav-items.types";

const INITIAL_STATE = {
  menuVisible: false,
  menuItems: [
    {
      title: "Blog Dashboard",
      to: "/blog-overview",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: "",
    },
    // {
    //   title: "Blog Posts",
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   to: "/blog-posts",
    // },
    // {
    //   title: "Add New Post",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/add-new-post",
    // },
    // {
    //   title: "Forms & Components",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview",
    // },
    {
      title: "Jobs",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/jobs",
    },
    {
      title: "User Profile",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-profile-lite",
    },
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors",
    // },
  ],
};

const navItemsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NavItemsActionsTypes.TOGGLE_SIDEBAR:
      return {
        ...state,
        menuVisible: !state.menuVisible,
      };
    default:
      return state;
  }
};

export default navItemsReducer;
