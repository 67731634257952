import axios from "axios";

const BASE_URL = "https://api.joltmate.com/v1/";

const clientUrl = (uri) => {
  const uriPath = uri.startsWith("/") ? uri : `/${uri}`;
  return `BASE_URL${uriPath}`;
};

const objectToFormData = (obj, form, namespace) => {
  const fd = form || new FormData();
  let formKey;
  const objectKeys = Object.keys(obj);

  objectKeys.forEach((property) => {
    if (namespace) {
      formKey = `${namespace}[${property}]`;
    } else {
      formKey = property;
    }

    const value = obj[property];

    if (Array.isArray(value)) {
      // value is an Array
      arrayToFormData(fd, formKey, value); // eslint-disable-line no-use-before-define
    } else if (typeof value === "object" && !(value instanceof File)) {
      // value is a nested object
      objectToFormData(value, fd, formKey);
    } else {
      // value is a string or a File object
      fd.append(formKey, value);
    }
  });

  return fd;
};

const arrayToFormData = (fd, formKey, value) => {
  value.forEach((a) => {
    if (typeof a === "object" && !(a instanceof File)) {
      // elements of array is nested object
      objectToFormData(value, fd, `${formKey}[]`);
    } else {
      // elements of array is string or File object
      fd.append(`${formKey}[]`, a);
    }
  });
};

const buildParam = (params, asJSON = true) => {
  if (asJSON) {
    return JSON.stringify(params);
  }
  return objectToFormData(params);
};

const getDefaultOptions = () => ({ method: "GET", headers: {} });

function ajax(uri, options = {}) {
  let defaultOptions = getDefaultOptions();

  defaultOptions = {
    ...defaultOptions,
    method: options.method || defaultOptions.method,
  };

  if (options.data) {
    if (options.formData) {
      defaultOptions.data = buildParam(options.data, false);
    } else {
      defaultOptions.headers["Content-Type"] =
        "application/json; charset=UTF-8";
      defaultOptions.data = buildParam(options.data);
    }
  }

  defaultOptions.url = clientUrl(uri);
  console.log(defaultOptions);
  return axios(defaultOptions);
}

function newFetch(method, url, options) {
  return new Promise((resolve, reject) => {
    let body = undefined;
    if (options && options.body) {
      body = JSON.stringify(options.body);
    }
    fetch(BASE_URL + url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method,
      body,
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          reject(response);
        }
        return response.json();
      })
      .then((response) => resolve(response))
      .catch((err) => {
        reject(err);
      });
  });
}

export async function post(url, options) {
  const res = await axios(url, { method: "POST", data: options.body, withCredentials: true });
  return res.data;
}
export async function remove(url, id) {
  const res = await axios(`${url}/${id}`, { method: "DELETE", withCredentials: true });
  return res.data;
}
export async function put(url, { body: { _id, ...options } }) {
  const res = await axios(`${url}/${_id}`, {
    method: "PUT",
    data: { _id, ...options },
    withCredentials: true,
  });
  return res.data;
}
export async function get(url) {
  const res = await axios(url, {withCredentials: true});
  return res.data;
}
