import { combineReducers } from "redux";

import jobReducer from "./job/job.reducer";
import navItemsReducer from "./nav-items/nav-items.reducer";
import userReducer from "./user/user.reducer";

export default combineReducers({
  jobs: jobReducer,
  navItems: navItemsReducer,
  user: userReducer,
});
