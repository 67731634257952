// import React,{useState,useEffect} from "react";
// import PropTypes from "prop-types";
// import {
//   Card,
//   CardHeader,
//   ListGroup,
//   ListGroupItem,
//   Row,
//   Col,
//   Form,
//   FormGroup,
//   FormInput,
//   FormSelect,
//   FormTextarea,
//   Breadcrumb,
//   BreadcrumbItem,
//   Button
// } from "shards-react";
// import { put } from "../../utils/http";
// import omit from 'lodash.omit';

// const UserAccountDetails = ({ title, currentPorfile, profileData }) => {
//   const [state,setState]=useState(currentPorfile);

//   useEffect(() => {
//     const handelProfile = () => {
//       setState({...currentPorfile,
//          address:currentPorfile.address.city + ',' +
//          currentPorfile.address.state + ','+
//          currentPorfile.address.country,})
//       }

//     handelProfile();

//   },[])

//   const handleChange = (e) => {
//     console.log([e.target.name])
//     if(e.target.name==='city'){
//       setState({
//         ...state,
//         [e.target.name]: e.target.value,
//         address:e.target.value + ',' +
//         state.state + ','+
//         state.country,
//       })
//     }

//     else if (e.target.name === 'state'){
//       setState({
//         ...state,
//         [e.target.name]: e.target.value,
//         address:state.city + ',' +
//         e.target.value + ',' +
//         state.country
//       })
//     }
//     else if(e.target.name === 'country'){
//       setState({
//         ...state,
//         [e.target.name]: e.target.value,
//         address:state.city + ',' +
//         state.state + ',' +
//         e.target.value
//       })
//     }

//     else{
//       setState({
//         ...state,
//         [e.target.name]: e.target.value,
//       });

//     }
//     console.log(state)
//   }

//   const handleSaveExit = async () => {
//     try {
//         await put("profile", {
//           body: {
//             ...profileData,
//             _id:profileData._id,
//             profile:{
//               fullName: state.firstName +' '+ state.lastName,
//               ...omit(state, ['city', 'country','state','firstName','lastName']),
//               address:{
//                 city:state.city,
//                 country:state.country,
//                 state:state.state,
//                 zip:state.zip
//               },
//             }
//           },
//         });
//     } catch (err) {
//       console.error(err);
//     }
//   };

//   return (
//   <Card small className="mb-4">
//     <CardHeader className="border-bottom">
//       <h6 className="m-0">{title}</h6>
//     </CardHeader>
//     <ListGroup flush>
//       <ListGroupItem className="p-3">
//         <Row>
//           <Col>
//             <Form>
//               <Row form>
//                 {/* First Name */}
//                 <Col md="6" className="form-group">
//                   <label htmlFor="firstName">First Name</label>
//                   <FormInput
//                     id="firstName"
//                     placeholder="First Name"
//                     name="firstName"
//                     value={state.firstName}
//                     onChange={(e) => handleChange(e)}
//                   />
//                 </Col>
//                 {/* Last Name */}
//                 <Col md="6" className="form-group">
//                   <label htmlFor="lastName">Last Name</label>
//                   <FormInput
//                     id="lastName"
//                     placeholder="Last Name"
//                     name="lastName"
//                     value={state.lastName}
//                     onChange={(e) => handleChange(e)}
//                   />
//                 </Col>
//               </Row>
//               <Row form>
//                 {/* Email */}
//                 <Col md="6" className="form-group">
//                   <label htmlFor="email">Email</label>
//                   <FormInput
//                     type="email"
//                     id="email"
//                     placeholder="Email Address"
//                     name="email"
//                     value={state.email}
//                     onChange={(e) => handleChange(e)}
//                     autoComplete="email"
//                   />
//                 </Col>
//                 {/* Password */}
//                 <Col md="6" className="form-group">
//                   <label htmlFor="fePassword">Password</label>
//                   <FormInput
//                     type="password"
//                     id="fePassword"
//                     placeholder="Password"
//                     value="EX@MPL#P@$$w0RD"
//                     onChange={(e) => handleChange(e)}
//                     autoComplete="current-password"
//                   />
//                 </Col>
//               </Row>
//               <label >Address</label>
//                 <Breadcrumb>
//                    <BreadcrumbItem active>{' '+state.address }</BreadcrumbItem>
//                  </Breadcrumb>
//               {/* <FormGroup>
//                 <label htmlFor="feAddress">Address</label>
//                 <FormInput
//                   id="address"
//                   placeholder="Address"
//                   name="address"
//                   value={state.address}
//                   onChange={(e) => handleChange(e)}
//                 />
//               </FormGroup> */}
//               <Row form>
//                 {/* City */}
//                 <Col md="6" className="form-group">
//                   <label htmlFor="city">City</label>
//                   <FormInput
//                     id="city"
//                     name="city"
//                     placeholder={state.city}
//                     onChange={(e) => handleChange(e)}
//                   />
//                 </Col>
//                    {/* State */}
//                    <Col md="6" className="form-group">
//                   <label htmlFor="state">State</label>
//                   <FormInput
//                     id="state"
//                     name="state"
//                     placeholder={state.state}
//                     onChange={(e) => handleChange(e)}
//                   />
//                 </Col>
//                 {/* Country */}
//                 <Col md="6" className="form-group">
//                   <label htmlFor="country">Country</label>
//                   <FormInput
//                     id="country"
//                     name="country"
//                     placeholder={state.country}
//                     onChange={(e) => handleChange(e)}
//                   />
//                 </Col>
//                 {/* Zip Code */}
//                 <Col md="2" className="form-group">
//                   <label htmlFor="zip">Zip</label>
//                   <FormInput
//                     id="zip"
//                     name="zip"
//                     placeholder={state.zip}
//                     onChange={(e) => handleChange(e)}
//                   />
//                 </Col>
//               </Row>
//               {/* <Row form>
//                 Description
//                 <Col md="12" className="form-group">
//                   <label htmlFor="feDescription">Description</label>
//                   <FormTextarea id="feDescription" rows="5" />
//                 </Col>
//               </Row> */}
//               <Button theme="accent" onClick={handleSaveExit}>Update Account</Button>
//             </Form>
//           </Col>
//         </Row>
//       </ListGroupItem>
//     </ListGroup>
//   </Card>
//   )
// };

// UserAccountDetails.propTypes = {
//   /**
//    * The component's title.
//    */
//   title: PropTypes.string
// };

// UserAccountDetails.defaultProps = {
//   title: "Account Details"
// };

// export default UserAccountDetails;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  Breadcrumb,
  BreadcrumbItem,
} from "shards-react";

const UserAccountDetails = ({ title, currentPorfile }) => {
  const [state, setState] = useState(currentPorfile);

  useEffect(() => {
    const FullName = currentPorfile.fullName.split(" ");

    const handelProfile = () => {
      setState({
        ...currentPorfile,
        address:
          currentPorfile.address.city +
          "," +
          currentPorfile.address.state +
          "," +
          currentPorfile.address.country,
        firstName: FullName[0],
        lastName: FullName[1],
        city: currentPorfile.address.city,
        country: currentPorfile.address.country,
        state: currentPorfile.address.state,
        zip: currentPorfile.address.zip,
      });
    };

    handelProfile();
  }, []);

  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form>
                <Row form>
                  {/* First Name */}
                  <Col className="form-group">
                    <label htmlFor="firstName">Full Name</label>
                    <Breadcrumb>
                      <BreadcrumbItem active>{`${state.firstName} ${
                        state.lastName || ""
                      }`}</BreadcrumbItem>
                    </Breadcrumb>
                  </Col>
                </Row>
                <Row form>
                  {/* Email */}
                  <Col md="6" className="form-group">
                    <label htmlFor="email">Email</label>
                    <Breadcrumb>
                      <BreadcrumbItem active>{state.email}</BreadcrumbItem>
                    </Breadcrumb>
                  </Col>
                  <Col md="6" className="form-group">
                    <label>Address</label>
                    <Breadcrumb>
                      <BreadcrumbItem active>
                        {" " + state.address}
                      </BreadcrumbItem>
                    </Breadcrumb>
                  </Col>
                </Row>
                <Row form>
                  {/* City */}
                  <Col md="6" className="form-group">
                    <label htmlFor="city">City</label>
                    <Breadcrumb>
                      <BreadcrumbItem active>{state.city}</BreadcrumbItem>
                    </Breadcrumb>
                  </Col>
                  {/* State */}
                  <Col md="6" className="form-group">
                    <label htmlFor="state">State</label>
                    <Breadcrumb>
                      <BreadcrumbItem active>{state.state}</BreadcrumbItem>
                    </Breadcrumb>
                  </Col>
                  {/* Country */}
                  <Col md="6" className="form-group">
                    <label htmlFor="country">Country</label>
                    <Breadcrumb>
                      <BreadcrumbItem active>{state.country}</BreadcrumbItem>
                    </Breadcrumb>
                  </Col>
                  {/* Zip Code */}
                  <Col md="2" className="form-group">
                    <label htmlFor="zip">Zip</label>
                    <Breadcrumb>
                      <BreadcrumbItem active>{state.zip}</BreadcrumbItem>
                    </Breadcrumb>
                  </Col>
                </Row>
                {/* <Row form>
                Description
                <Col md="12" className="form-group">
                  <label htmlFor="feDescription">Description</label>
                  <FormTextarea id="feDescription" rows="5" />
                </Col>
              </Row> */}
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
};

UserAccountDetails.defaultProps = {
  title: "Account Details",
};

export default UserAccountDetails;
