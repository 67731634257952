import React,{useEffect} from "react";
import { Container, Row, Col } from "shards-react";
import * as ROUTES from "../constants/API-routes";
import { get } from "../utils/http";
import isEmpty from 'lodash.isempty'
import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/user-profile-lite/UserDetails";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";
import { connect } from "react-redux";
import UserActionsTypes from '../redux/user/user.types'

const UserProfileLite = ({currentPorfile ,setCurrentPorfile}) => {
 
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profile = await get(ROUTES.PROFILE);
        setCurrentPorfile(profile.profile)
       
      } catch (err) {
        console.error(err.message);
      }
    };
    fetchProfile();

  },[])

  return(
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="User Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <Row>
      <Col lg="4">
      {!isEmpty(currentPorfile) && <UserDetails currentPorfile={currentPorfile}/>}

      </Col>
      <Col lg="8">
      {!isEmpty(currentPorfile) && <UserAccountDetails currentPorfile={currentPorfile}/>}
      </Col>
    </Row>
  </Container>
  )
  }
  const mapStateToProps = ({user})=>({
    currentPorfile: user.currentUser,
  });
  const dispatchToProps = (dispatch) => ({
    setCurrentPorfile:(payload)=>{
      dispatch({
          type:UserActionsTypes.SET_CURRENT_USER,
          payload
      })
  },
  });

export default connect(mapStateToProps,dispatchToProps) (UserProfileLite);
