const JobActionsTypes = {
  FETCH_JOBS_START: "FETCH_JOBS_START",
  FETCH_JOBS_SUCCESS: "FETCH_JOBS_SUCCESS",
  FETCH_JOBS_FAILURE: "FETCH_JOBS_FAILURE",
  SET_FORM_MODE:'SET_FORM_MODE',
  SET_FILE_DATA:'SET_FILE_DATA',
  RESET_FILE_DATA:'RESER_FILE_DATA',
  UPDATE_FILE_DATA:'UPDATE_FILE_DATA',
  CHECK_JOBS_ERROR:'CHECK_JOBS_ERROR',

};

export default JobActionsTypes;
