import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../redux/user/user.selector";
import { setCurrentUser } from "../redux/user/user.actions";
import { useHistory, Redirect } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardImg,
  CardBody,
  Button,
  Form,
  FormInput,
  FormGroup,
} from "shards-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import logo from "../assets/logo.jpg";
import * as ROUTES from "../constants/API-routes";
import { post, get } from "../utils/http";

const LoginForm = ({ setCurrentUser }) => {
  const history = useHistory();
  const [state, setState] = useState({
    phone: "",
    password: "",
  });
  const [isError, setIsError] = useState(false);

  const login = async () => {
    try {
      const body = {
        password: state.password,
        phone: state.phone,
      };
      await post(ROUTES.LOGIN, { body });
      const profile = await get(ROUTES.PROFILE);
      setCurrentUser(profile.profile);
      history.push("/jobs");
    } catch (err) {
      console.error(err);
      setIsError(true);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    login();
  };

  return (
    <Card>
      <CardHeader>
        <div className="logo-container">
          <CardImg src={logo} className="login-logo" />
        </div>
      </CardHeader>
      <CardBody>
        <Col className="login-center">
          <Form className="login-form" onSubmit={handleLogin}>
            <FormGroup>
              <label htmlFor="phone">Phone</label>
              <PhoneInput
                id="phone"
                name="phone"
                country={"us"}
                value={state.phone}
                inputClass="input-class"
                onChange={(phone) =>
                  setState({
                    ...state,
                    phone,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="password">Password</label>
              <FormInput
                type="password"
                id="password"
                name="password"
                placeholder="Enter Password"
                value={state.password}
                onChange={(e) =>
                  setState({
                    ...state,
                    password: e.target.value,
                  })
                }
              />
            </FormGroup>
            {isError && <div className="error-message">Wrong credentials.</div>}
            <div className="login-actions">
              {/*<a href="https://api.joltmate.com/v1/adm/auth/forgot">
                Forgot password?
              </a> */}
              <Button type="submit">Login</Button>
            </div>
          </Form>
        </Col>
      </CardBody>
    </Card>
  );
};

const Login = ({ setCurrentUser, currentUser }) => {
  return (
    <>
      {currentUser ? (
        <Redirect to="/jobs" />
      ) : (
        <Container fluid>
          <Row className="login-container">
            <Col md="3" />
            <Col md="6" sm="12">
              <LoginForm setCurrentUser={(user) => setCurrentUser(user)} />
            </Col>
            <Col md="3" />
          </Row>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
