import React from "react";
import { Container, Row, Col } from "shards-react";

const EmptyLayout = ({ children }) => {
  return (
    <Container fluid>
      <Row>
        <Col tag="main">{children}</Col>
      </Row>
    </Container>
  );
};

export default EmptyLayout;
