import React from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../../../redux/nav-items/nav-items.actions";

const NavbarToggle = (props) => {
  const handleClick = () => {
    const { toggleSidebar } = props;
    toggleSidebar();
  };

  return (
    <nav className="nav">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        onClick={handleClick}
        className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center"
      >
        <i className="material-icons">&#xE5D2;</i>
      </a>
    </nav>
  );
};

const dispatchToProps = (dispatch) => ({
  toggleSidebar: () => dispatch(toggleSidebar()),
});

export default connect(null, dispatchToProps)(NavbarToggle);
