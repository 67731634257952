import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectNavMenuItems } from "../../../redux/nav-items/nav-items.selectors";

const SidebarNavItems = ({ items }) => {
  console.log(items)
  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders flex-column">
        {items.map((item, idx) => (
          <SidebarNavItem key={idx} item={item} />
        ))}
      </Nav>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  items: selectNavMenuItems,
});

export default connect(mapStateToProps)(SidebarNavItems);
