import React from "react";
import { connect } from "react-redux";
import { selectJobItems } from "../../redux/job/job.selectors";
import { createStructuredSelector } from "reselect";
import { Row, Col, Card, CardBody } from "shards-react";
import { FaPen, FaTrash } from "react-icons/fa";

const JobTable = ({ jobItems, updateJob, deleteJob }) => {
  const setFirstsTwoSkills = (skills) => {
    if (skills[0] && skills[1]) {
      return `${skills[0]}, ${skills[1]}`;
    } else if (skills[0]) {
      return `${skills[0]}`;
    }
  };

  return (
    <Row>
      <Col>
        <Card small className="mb-4">
          <CardBody className="p-0 pb-3">
            <table className="table mb-0 text-center">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    Company
                  </th>
                  <th scope="col" className="border-0">
                    Job Title
                  </th>
                  <th scope="col" className="border-0">
                    Location
                  </th>
                  <th scope="col" className="border-0">
                    Skills
                  </th>
                  {window.innerWidth > 960 && (
                    <>
                      <th scope="col" className="border-0">
                        Type
                      </th>
                      <th scope="col" className="border-0">
                        Salary
                      </th>
                      <th scope="col" className="border-0">
                        Applied
                      </th>
                      <th scope="col" className="border-0">
                        Actions
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              {jobItems && (
                <tbody>
                  {jobItems.map((job) => (
                    <tr key={job._id}>
                      <td>{job.company}</td>
                      <td>{job.jobTitle}</td>
                      <td>{`${job.country}, ${job.city}`}</td>
                      <td>{setFirstsTwoSkills(job.skills)}</td>
                      {window.innerWidth > 960 && (
                        <>
                          <td>{job && job.type === "freelance" ? "contract" : job.type}</td>
                          <td>{job.salary}</td>
                          <td>{job.appliedBy.length}</td>
                          <td className="actions-col">
                            {job.sharedBy && job.sharedBy.length === 0 ? (
                              <>
                                <FaPen onClick={(e) => updateJob(e, job)} />
                                <FaTrash onClick={(e) => deleteJob(e, job)} />
                              </>
                            ) : (
                              <div className="text-primary">Shared Job</div>
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = createStructuredSelector({
  jobItems: selectJobItems,
});

export default connect(mapStateToProps)(JobTable);
