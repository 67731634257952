import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectMenuVisible } from "../../../redux/nav-items/nav-items.selectors";
import classNames from "classnames";
import { Col } from "shards-react";

import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarSearch from "./SidebarSearch";
import SidebarNavItems from "./SidebarNavItems";

const MainSidebar = ({ menuVisible }) => {
  const classes = classNames(
    "main-sidebar",
    "px-0",
    "col-12",
    menuVisible && "open"
  );

  return (
    <Col tag="aside" className={classes} lg={{ size: 2 }} md={{ size: 3 }}>
      <SidebarMainNavbar />
      <SidebarSearch />
      <SidebarNavItems />
    </Col>
  );
};

const mapStateToProps = createStructuredSelector({
  menuVisible: selectMenuVisible,
});

export default connect(mapStateToProps)(MainSidebar);
