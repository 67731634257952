import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import { connect } from "react-redux";
import get from 'lodash.get'
import UserActionsTypes from '../../../../redux/user/user.types'
import ivaterImage from '../../../../images/avatars/0.jpg'
 class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      image:get (props.currentPorfile,'photos','')
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

   onError=(error)=>{
    this.setState({
      image:ivaterImage
    })
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }
  handelLogOut = ()=> {
    const {setCurrentPorfile}=this.props;
    setCurrentPorfile(null)

  }

  render() {
    const {currentPorfile}=this.props;
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          <img
            className="user-avatar rounded-circle mr-2"
            src={this.state.image}
            onError={this.onError.bind(this)}
            alt="User Avatar"
          />{" "}
          <span className="d-none d-md-inline-block">{get (currentPorfile,'fullName','')}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={Link} to="user-profile-lite">
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem>
          {/* <DropdownItem tag={Link} to="edit-user-profile">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="file-manager-list">
            <i className="material-icons">&#xE2C7;</i> Files
          </DropdownItem>
          <DropdownItem tag={Link} to="transaction-history">
            <i className="material-icons">&#xE896;</i> Transactions
          </DropdownItem> */}
          <DropdownItem divider />
          <DropdownItem tag={Link} to="" className="text-danger" onClick={this.handelLogOut}>
            <i className="material-icons text-danger" >&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}

const mapStateToProps = ({user})=>({
  currentPorfile: user.currentUser,
});

const dispatchToProps = (dispatch) => ({
  setCurrentPorfile:(payload)=>{
    dispatch({
        type:UserActionsTypes.SET_CURRENT_USER,
        payload
    })
},
});

export default connect (mapStateToProps,dispatchToProps) (UserActions)

