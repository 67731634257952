import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchJobsStartAsync } from "../../redux/job/job.actions";
import JobActionsTypes from "../../redux/job/job.types";
import { usePosition } from "use-position";
import isEmpty from "lodash.isempty";
import size from "lodash.size";
import { get } from "../../utils/http";
import _get from "lodash.get";
import { BASE_URL } from "../../constants/API-routes";

const ErrorFile = ({ fileData, checkErrors, hasError }) => {
  const [industries, setIndustries] = useState([]);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const industries = await get(BASE_URL+"job/industry");
        setIndustries(industries);
      } catch (err) {
        console.error(err.message);
      }
    };

    const fetchSkills = async () => {
      try {
        const dbSkills = await get(BASE_URL+"skill");
        let skillsOptions = [];
        dbSkills.forEach((skill) => {
          skillsOptions.push(skill.name);
        });
        setSkills(skillsOptions);
      } catch (err) {
        console.error(err.message);
      }
    };

    fetchIndustries();
    fetchSkills();
  }, []);

  const handelErrors = () => {
    const error = [];
    const types = {
      jobTypes: [
        "full-time",
        "contract",
        "part-time",
        "internship",
        "freelance",
      ],
      salaries: ["Per hour", "Per month", "Per year", "Depending Experience"],
    };

    fileData.map((d, v) => {
      const err = {};
      err.row = `In Row Number ${v + 1}`;

      if (isEmpty(d.company)) {
        err.company = "comany Filed is requird";
      }
      if (isEmpty(d.jobTitle)) {
        err.jobTitle = "Job Title Filed is requird";
      }

      const industryFile = industries.find(
        (industry) => industry.name === d.industry
      );
      if (size(industryFile) == 0) {
        err.industry = `You Should Enter Industry like That [${industries.map(
          (d) => {
            return d.name;
          }
        )}]`;
        err.subIndustry = `Invaled Sub Industry`;
      } else {
        const subindustries = industryFile.subIndustry.find(
          (sub) => sub.name === d.subIndustry
        );
        // if (size(subindustries) == 0) {
        //   err.subIndustry = `You Should Enter Sub Industry like That [${industryFile.subIndustry.map(
        //     (d) => {
        //       return d.name;
        //     }
        //   )}]`;
        // }
      }

      if (isEmpty(d.skills)) {
        err.skills = "Skills Filed is requird ";
      }
      // else{
      //   d.skills.map(skill=>{
      //     if(!skills.includes(skill)){
      //       err.skills=`You Should Enter Skills Like That [${skills.map(d=>d)}]`
      //     }
      //   })
      //   console.log(skills,d.skills)

      // }

      const checkJobType = types.jobTypes.find((type) => type === d.type);
      if (size(checkJobType) == 0) {
        err.jobType = `You Should Enter Job Type like That [${types.jobTypes.map(
          (d) => {
            return d;
          }
        )}]`;
      }

      if (isNaN(d.salary)) {
        err.salary = "Please Enter Valied Number";
      }

      const checkSalariesType = types.salaries.find(
        (salary) => salary === d.salaryType
      );
      if (size(checkSalariesType) == 0) {
        err.salaryType = `You Should Enter Salary Type like That [${types.salaries.map(
          (d) => {
            return d;
          }
        )}]`;
      }

      if (isEmpty(d.contactName)) {
        err.contactName = "Contatct Name Filed is requird";
      }

      const testEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(d.contact);
      if (!testEmail) {
        err.email = "Please Enter Vailed Email";
      }

      if (isNaN(d.zip)) {
        err.zip = "Zip Filed is Not Vailed";
      }

      if (isEmpty(d.country)) {
        err.country = "Country Filed is requird";
      }

      if (isEmpty(d.city)) {
        err.city = "City Filed is requird";
      }

      if (isEmpty(d.description)) {
        err.description = "Description Filed is requird";
      }

      if (size(err) !== 1) {
        error.push(err);
      }
    });

    if (isEmpty(error)) {
      checkErrors(true);
    } else {
      checkErrors(false);
    }

    return error.map((key) => {
      return (
        <div style={{ margin: "10px", listStyleType: "none" }}>
          {Object.keys(key).map((err) => {
            return <li>{`${key[err]}`}</li>;
          })}
        </div>
      );
    });
  };

  return (
    <div style={{ fontSize: "12px", margin: "20px", color: "rgb(225,0,0)" }}>
      {!hasError && (
        <p style={{ fontWeight: 800 }}>
          Fix these errors by clicking EDIT next to each job or upload corrected
          file again
        </p>
      )}
      {handelErrors()}
    </div>
  );
};

const mapStateToProps = ({ jobs }) => ({
  hasError: jobs.hasError,
});

const dispatchToProps = (dispatch) => ({
  fetchJobsStartAsync: () => dispatch(fetchJobsStartAsync()),
  changeModeForm: (payload) => {
    dispatch({
      type: JobActionsTypes.SET_FORM_MODE,
      payload,
    });
  },
  checkErrors: (payload) => {
    dispatch({
      type: JobActionsTypes.CHECK_JOBS_ERROR,
      payload,
    });
  },
});

export default connect(mapStateToProps, dispatchToProps)(ErrorFile);
