import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Progress,
} from "shards-react";

const UserDetails = ({ userDetails, currentPorfile, avatar }) => {
  const name = currentPorfile.fullName.split(" ");
  const [image, setImage] = useState("");

  useEffect(() => {
    const fetchImage = () => {
      setImage(currentPorfile.photos);
    };
    fetchImage();
  }, []);

  const onError = (error) => {
    setImage(userDetails.avatar);
  };

  return (
    <Card small className="mb-4 pt-3">
      {/* {console.log(userDetails)} */}
      <CardHeader
        className="border-bottom text-center"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="mb-3 mx-auto">
          <img
            className="rounded-circle"
            src={image}
            alt={currentPorfile.fullName}
            onError={() => onError()}
            width="100"
          />
        </div>
        <h4 className="mb-0">{name[0]}</h4>
        <span className="text-muted d-block mb-2">
          {currentPorfile.mainlyHereFor}
        </span>
        {/* <Button pill outline size="sm" className="mb-2">
        <i className="material-icons mr-1">person_add</i> Follow
      </Button> */}
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="px-4">
          <div className="progress-wrapper">
            <strong className="text-muted d-block mb-2">
              {userDetails.performanceReportTitle}
            </strong>
            <Progress
              className="progress-sm"
              value={userDetails.performanceReportValue}
            >
              <span className="progress-value">
                {userDetails.performanceReportValue}%
              </span>
            </Progress>
          </div>
        </ListGroupItem>
        <ListGroupItem className="p-4">
          <strong className="text-muted d-block mb-2">
            {userDetails.metaTitle}
          </strong>
          <span>{currentPorfile.summary}</span>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

UserDetails.propTypes = {
  /**
   * The user details object.
   */
  userDetails: PropTypes.object,
};

UserDetails.defaultProps = {
  userDetails: {
    name: "Sierra Brooks",
    avatar: require("./../../images/avatars/0.jpg"),
    jobTitle: "Project Manager",
    performanceReportTitle: "Workload",
    performanceReportValue: 74,
    metaTitle: "Description",
    metaValue:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda eligendi cumque?",
  },
};

export default UserDetails;
