import JobActionsTypes from "./job.types";
import * as ROUTES from "../../constants/API-routes";
import { get } from "../../utils/http";
import { setCurrentUser } from "../user/user.actions";
export const fetchJobsStart = () => ({
  type: JobActionsTypes.FETCH_JOBS_START,
});

export const fetchJobsSuccess = (jobs) => ({
  type: JobActionsTypes.FETCH_JOBS_SUCCESS,
  payload: jobs,
});

export const fetchJobsFailure = (errorMessage) => ({
  type: JobActionsTypes.FETCH_JOBS_FAILURE,
  payload: errorMessage,
});

export const fetchJobsStartAsync = () => {
  return (dispatch) => {
    dispatch(fetchJobsStart());
    get(ROUTES.PROFILE)
      .then((profile) => {
        dispatch(fetchJobsSuccess(profile.jobs));
        dispatch(setCurrentUser(profile.profile));
      })
      .catch((error) => {
        dispatch(fetchJobsFailure(error.message));
      });
  };
};

export const changeModeForm = (Mode) => ({
  type: JobActionsTypes.SET_FORM_MOD,
  payload: Mode,
});

export const changeFileData = (fileData) => ({
  type: JobActionsTypes.SET_FILE_DATA,
  payload: fileData,
});

export const reserFileData = () => ({
  type: JobActionsTypes.RESET_FILE_DATA,
});

export const updateFileData = (updateData) => ({
  type: JobActionsTypes.UPDATE_FILE_DATA,
  payload: updateData,
});

export const checkErrors = (data) => ({
  type: JobActionsTypes.CHECK_JOBS_ERROR,
  payload: data,
});
