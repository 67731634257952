import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectIsJobsFetching } from "../redux/job/job.selectors";
import JobActionsTypes from "../redux/job/job.types";
import {
  Container,
  Row,
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
} from "shards-react";
import { JobTable, JobForm } from "../components/jobs";
import Spinner from "../components/common/Spinner";
import PageTitle from "../components/common/PageTitle";
import { fetchJobsStartAsync } from "../redux/job/job.actions";
import { remove } from "../utils/http";
import { BASE_URL } from "../constants/API-routes";

const Tables = ({ fetchJobsStartAsync, isJobsFetching, changeModeForm }) => {
  const INITIAL_STATE = {
    company: "",
    jobTitle: "",
    industry: "",
    subIndustry: "",
    skills: [],
    type: "",
    salary: "",
    salaryType: "",
    contactName: "",
    contact: "",
    zip: "",
    country: "",
    city: "",
    description: "",
    mode: "remote",
    isCurated: true,
  };

  const [isFormOpened, toggleIsFormOpened] = useState(false);
  const [currentJob, setCurrentJob] = useState(INITIAL_STATE);
  const [isConfirmDeleteOpened, toggleIsConfirmDeleteOpened] = useState(false);

  useEffect(() => {
    fetchJobsStartAsync();
  }, [fetchJobsStartAsync]);

  const handleUpdateJob = (e, job) => {
    changeModeForm("form");
    setCurrentJob({
      ...job,
      industry: job.industry.industry,
      subIndustry: job.industry.subIndustry,
      skills: job.skills.map((skill) => {
        return {
          value: skill,
          label: skill,
        };
      }),
    });
    toggleIsFormOpened(!isFormOpened);
  };

  const openConfirmDialog = (e, job) => {
    toggleIsConfirmDeleteOpened(!isConfirmDeleteOpened);
    setCurrentJob(job);
  };

  const handleDeleteJob = async () => {
    toggleIsConfirmDeleteOpened(!isConfirmDeleteOpened);
    await remove(BASE_URL+"job", currentJob._id);
    fetchJobsStartAsync();
    setCurrentJob(INITIAL_STATE);
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4 table-title">
        <PageTitle sm="4" title="My Jobs" className="text-sm-left" />
        <div style={{ marginTop: 20 }}>
          <Button
            className="text-md"
            style={{ marginRight: "20px" }}
            onClick={() => {
              toggleIsFormOpened(!isFormOpened);
              setCurrentJob(INITIAL_STATE);
              changeModeForm("form");
            }}
          >
            Add a job
          </Button>
          <Button
            className="text-md"
            onClick={() => {
              toggleIsFormOpened(!isFormOpened);
              setCurrentJob(INITIAL_STATE);
              changeModeForm("file");
            }}
          >
            Upload Jobs (.xlsx)
          </Button>
        </div>
      </Row>
      <JobForm
        open={isFormOpened}
        toggle={() => toggleIsFormOpened(!isFormOpened)}
        currentJob={currentJob}
      />
      <Modal
        open={isConfirmDeleteOpened}
        toggle={() => toggleIsConfirmDeleteOpened(!isConfirmDeleteOpened)}
        size="sm"
        centered={true}
      >
        <ModalHeader>Delete job permanently?</ModalHeader>
        <ModalFooter>
          <Button onClick={handleDeleteJob}>Yes</Button>
          <Button
            onClick={() => toggleIsConfirmDeleteOpened(!isConfirmDeleteOpened)}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
      {isJobsFetching ? (
        <Spinner />
      ) : (
        <JobTable updateJob={handleUpdateJob} deleteJob={openConfirmDialog} />
      )}
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  isJobsFetching: selectIsJobsFetching,
});

const dispatchToProps = (dispatch) => ({
  fetchJobsStartAsync: () => dispatch(fetchJobsStartAsync()),
  changeModeForm: (payload) => {
    dispatch({
      type: JobActionsTypes.SET_FORM_MODE,
      payload,
    });
  },
});

export default connect(mapStateToProps, dispatchToProps)(Tables);
