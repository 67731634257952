import { createSelector } from "reselect";

const selectJob = (state) => state.jobs;

export const selectJobItems = createSelector([selectJob], (job) => job.items);

export const selectIsJobsFetching = createSelector(
  [selectJob],
  (job) => job.isFetching
);
